@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
/* GENERIC */

* {
  padding: 0px;
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
}

body {
  background-image: url('images/hero.jpg');
  background-size: cover;
  background-color: #1b1742;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
}

html, body, #root {
  height: 100%;
}

a {
  color: #1b1742;
}

a:hover {
  color: #1c4997;
}

.hero a {
  text-decoration: none;
}

.hero a:hover {
  text-decoration: underline;
}

h1 {
  font-size: 110px;
  line-height: 114px;
}

h2 {
  font-size: 80px;
  line-height: 84px;
  margin: 0 0 10px 0;
}

h3 {
  font-size: 40px;
  line-height: 44px;
  margin: 0 0 10px 0;
}

h1, h2, h3, h4, h5 {
  font-family: 'Bebas Neue', sans-serif;
  text-transform: uppercase;
  color: #1b1742;
  font-weight: normal;
}

.inner {
  margin: 0 auto;
  padding: 30px;
  max-width: 700px;
}

.angletop, .anglebottom {
  width: 100%;
  height: 140px;
  background-size: 100% 100%;
}

.anglebottom i {
  margin: 100px 0 0 0;
}

.contact, #about, #projects, footer {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chevrons {
  color: #ffffff;
  font-size: 30px;
  line-height: 30px;
}

/* HERO */

.hero {
  height: 100%;
}

.hero li {
  display: inline-block;
  padding: 10px 0px 0px 20px;
}

.hero li, .hero li a {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 40px;
  line-height: 44px;
}

.hero *, .hero *:hover {
  color: #ffffff;
  width: 100%;
}

.hero .inner {
  position: absolute;
  width: auto;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hero h2 {
  font-size: 56px;
  line-height: 60px;
}

.hero ul {
  margin: 0px;
  padding: 0px;
}

.hero ul li {
  width: auto;
  margin: 0px;
  padding: 12px;
  display: inline;
}

.hero ul li a {
  display: inline;
  font-weight: normal;
  font-size: 28px;
  line-height: 28px;
}

.hero ul li a:hover {
  text-decoration: none;
  opacity: 0.8;
}

.hero ul li a i {
  display: inline;
  margin: 0 0 0 7px;
}

/* PROJECTS */

#projects .angletop {
  background-image: url('./images/block-angle-top-white.png');
}

#projects .anglebottom {
  background-image: url('./images/block-angle-bottom-white.png');
}

#projects .colorbg {
  background-color: #f4f4f4;
}

#projects .inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 30px 30px 30px;
}

.project {
  border-radius: 4px;
  margin: 30px 0 0px 0;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #cccccc;
  text-align: left;
}

.projectinfo {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: inherit;
}

.projectinfo p {
  font-size: 13px;
  line-height: 19px;
  text-align: inherit;
}

.textblock {
  min-height: 260px;
}

.projectimage {
  font-size: 1px;
  line-height: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: right;
  background-position: top left;
  background-size: cover;
  background-color: #1c4997;
  min-width: 300px;
  min-height: 300px;
}

#garms{
background-image: url('./images/garms.gif');
}

#arrivr{
background-image: url('./images/arrivr.gif');
}

#spaceinvaders{
background-image: url('./images/spaceinvaders.gif');
}

.buttons {
  padding: 30px 0 0 0;
}

.button {
  display: inline-block;
  border: none;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  background-color: #1b1742;
  color: #ffffff;
}

.back {
  background-color: #1b1742;
  border-radius: 0px;
  align-self: flex-end;
}

.view:hover, .github:hover {
  background-color: #1c4997;
  color: #ffffff;
}

.github i {
  margin: 0 10px 0 0;
}

.view {
  margin: 0 10px 0 0;
}

.thumbnails {
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.thumbnail {
  width: 32%;
  font-size: 1px;
  line-height: 1px;
  cursor: pointer;
}

.thumbnail img {
  width: 100%;
  max-width: 100%;
  opacity: 1;
}

.thumbnail img:hover {
  opacity: 0.8;
}

/* ABOUT */

#about .angletop {
  background-image: url('./images/block-angle-top.png');
}

#about .anglebottom {
  background-image: url('./images/block-angle-bottom.png');
}

#about .colorbg {
  background-image: url('./images/angle-bg.png');
  background-size: cover;
  background-color: #1b1742;
}

#about .inner h2, #about .inner p {
  color: #ffffff;
}

/* FOOTER */


footer .chevrons {
  margin: 0 0 20px 0;
}

footer p, footer h2, footer a {
  color: #ffffff;
}

footer p {
  font-size: 20px;
  line-height: 25px;
  margin: 0 0 30px 0;
}

footer p i {
  margin: 0 5px 0 10px;
}

footer a:hover {
  color: #ffffff;
}

footer .button {
  display: block;
  width: auto;
  margin: 0 0 10px 0;
  background-color: #ffffff;
  color: #1b1742;
  font-size: 20px;
  line-height: 25px;
}

footer .button:hover {
  background-color: #f4f4f4;
  color: #1b1742;
  opacity: 0.8;
}

/* MOBILE STYLES */

@media only screen and (max-width: 800px) {
  .project {
    flex-direction: column-reverse;
    text-align: center;
    max-width:400px;
    margin: 30px auto 0 auto;
  }
  .projectinfo, .projectimage {
    width: auto;
  }
  .projectinfo {
    margin: 0 0 0 0;
  }
  h1 {
    font-size: 60px;
    line-height: 64px;
  }
  h2, .hero h2 {
    font-size: 30px;
    line-height: 34px;
    margin: 0 0 10px 0;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 10px 0;
  }
  .hero ul li {
    padding: 8px;
  }
  .hero ul li a {
    font-size: 16px;
    line-height: 16px;
  }
  .projectimage {
    min-width: 260px;
    min-height: 260px;
  }
}